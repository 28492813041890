import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import theme from './theme';
import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: ['Lobster Two:300,400,500,700', 'Montserrat:300,400,500,700', 'Roboto:300,400,500,700', 'sans-serif']
  }
});

// import { messaging } from './firebase';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// optionally clear cache files
serviceWorkerRegistration.clearCache()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// logging our messaging notification
// messaging.onMessage((payload:any) => {
//   console.log(payload);
// })
