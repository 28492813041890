import { GameController } from "./GameController"

export const isPlaying = {
  correct: false,
  pass: false,
  roundOver: false,
  roundStart: false,
  undo: false,
  invalid: false,
  nextTeam: false,
  settings: false,
  home: false,
  play: false,
  resetTimer: false,
  startTimer: false,
  pauseTimer: false,
  audioBeat: false,
  audioExpire: false,
  gameOver: false
}

export const audioBeat = new Audio(process.env.PUBLIC_URL + '/sounds/HeartBeat.mp3');
audioBeat.load()
audioBeat.onplaying = () => isPlaying['audioBeat'] = true
audioBeat.onpause = () => {isPlaying['audioBeat'] = false}


export const audioExpire = new Audio(process.env.PUBLIC_URL + '/sounds/PowerDown3.mp3');
audioExpire.load()
audioExpire.onplaying = () => isPlaying['audioExpire'] = true
audioExpire.onpause = () => {isPlaying['audioExpire'] = false}


const correct = new Audio(process.env.PUBLIC_URL + '/sounds/SynthChime6.mp3');
correct.load()
correct.onplaying = () => isPlaying['correct'] = true
correct.onpause = () => {
  isPlaying['correct'] = false
}

const pass = new Audio(process.env.PUBLIC_URL + '/sounds/PowerDown7.mp3');
pass.load()
pass.onplaying = () => isPlaying['pass'] = true
pass.onpause = () => {
  isPlaying['pass'] = false
}

const roundOver = new Audio(process.env.PUBLIC_URL + '/sounds/QuizShowNextLevel.mp3');
roundOver.load()
roundOver.onplaying = () => isPlaying['roundOver'] = true
roundOver.onpause = () => {
  isPlaying['roundOver'] = false
}

const gameOver = new Audio(process.env.PUBLIC_URL + '/sounds/GameWinner.mp3');
gameOver.load()
gameOver.onplaying = () => isPlaying['gameOver'] = true
gameOver.onpause = () => {
  isPlaying['gameOver'] = false
}

const roundStart = new Audio(process.env.PUBLIC_URL + '/sounds/IntroBrightPositiveLogoOpenerShort.mp3');
roundStart.load()
roundStart.onplaying = () => isPlaying['roundStart'] = true
roundStart.onpause = () => {
  isPlaying['roundStart'] = false
}

const undo = new Audio(process.env.PUBLIC_URL + '/sounds/UI_Quirky27.mp3');
undo.load()
undo.onplaying = () => isPlaying['undo'] = true
undo.onpause = () => {
  isPlaying['undo'] = false
}

const invalid = new Audio(process.env.PUBLIC_URL + '/sounds/UI_Quirky2.mp3');
invalid.load()
invalid.onplaying = () => isPlaying['invalid'] = true
invalid.onpause = () => {
  isPlaying['invalid'] = false
}

const nextTeam = new Audio(process.env.PUBLIC_URL + '/sounds/Heal.mp3');
nextTeam.load()
nextTeam.onplaying = () => isPlaying['nextTeam'] = true
nextTeam.onpause = () => {
  isPlaying['nextTeam'] = false
}

const startTimer = new Audio(process.env.PUBLIC_URL + '/sounds/UI_Quirky11.mp3');
startTimer.load()
startTimer.onplaying = () => isPlaying['startTimer'] = true
startTimer.onpause = () => {
  isPlaying['startTimer'] = false
}

const pauseTimer = new Audio(process.env.PUBLIC_URL + '/sounds/UI_Quirky12.mp3');
pauseTimer.load()
pauseTimer.onplaying = () => isPlaying['pauseTimer'] = true
pauseTimer.onpause = () => {
  isPlaying['pauseTimer'] = false
}

const resetTimer = new Audio(process.env.PUBLIC_URL + '/sounds/UI_Quirky18.mp3');
resetTimer.load()
resetTimer.onplaying = () => isPlaying['resetTimer'] = true
resetTimer.onpause = () => {
  isPlaying['resetTimer'] = false
}


export class AudioController {
  gameState: GameController

  constructor(gameState: GameController) {
    this.gameState = gameState
  }

  canPlay(sound:string){
    if(!this.gameState.settings.useSounds){
      return false
    }
    if (!this.gameState.settings.activeSounds.includes(sound)) {
      return false
    }
    return true
  }

  playAudioBeat(){
    audioBeat.currentTime = 0
    if(audioBeat.paused && !isPlaying['audioBeat'] && this.canPlay('beat')){
      audioBeat.play()
    }
  }

  playAudioExpire(){
    audioExpire.currentTime =0
    if(audioExpire.paused && !isPlaying['audioExpire'] && this.canPlay('timerExpire')){
      audioExpire.play()
    }
  }

  playResetTimer() {
    resetTimer.currentTime = 0
    if (resetTimer.paused && !isPlaying['resetTimer'] && this.canPlay('timerReset')) {
      resetTimer.play()
    }
  }

  playPauseTimer() {
    pauseTimer.currentTime = 0
    if (pauseTimer.paused && !isPlaying['pauseTimer'] && this.canPlay('timerPause')) {
      pauseTimer.play()
    }
  }

  playStartTimer() {
    startTimer.currentTime = 0
    if (startTimer.paused && !isPlaying['startTimer'] && this.canPlay('timerStart')) {
      startTimer.play()
    }
  }

  playNextTeam() {
    nextTeam.currentTime = 0
    if (nextTeam.paused && !isPlaying['nextTeam'] && this.canPlay('nextTeam')) {
      nextTeam.play()
    }
  }

  playInvalid() {
    invalid.currentTime = 0
    if (invalid.paused && !isPlaying['invalid'] && this.canPlay('invalid')) {
      invalid.play()
    }
  }

  playUndo() {
    undo.currentTime = 0
    if (undo.paused && !isPlaying['undo'] && this.canPlay('undo')) {
      undo.play()
    }
  }

  playRoundStart() {
    roundStart.currentTime = 0
    if (roundStart.paused && !isPlaying['roundStart'] && this.canPlay('roundStart')) {
      roundStart.play()
    }
  }

  playRoundOver() {
    roundOver.currentTime = 0
    if (roundOver.paused && !isPlaying['roundOver'] && this.canPlay('roundOver')) {
      roundOver.play()
    }
  }

  playGameOver() {
    gameOver.currentTime = 0
    if (gameOver.paused && !isPlaying['gameOver'] && this.canPlay('roundOver')) {
      gameOver.play()
    }
  }

  playCorrect() {
    correct.currentTime = 0
    if (correct.paused && !isPlaying['correct'] && this.canPlay('cardCorrect')) {
      correct.play()
    }
  }

  playPass() {
    pass.currentTime = 0
    if (pass.paused && !isPlaying['pass'] && this.canPlay('cardPass')) {
      pass.play()
    }
  }

  loadSounds() {
    correct.load()
    pass.load()
    roundStart.load()
    roundOver.load()
    undo.load()
    invalid.load()
    nextTeam.load()
  }
}
