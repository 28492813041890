import React, { lazy, Suspense, useEffect } from 'react';
import { GameController } from "./app/controller/GameController"
import { observer } from "mobx-react"
import { AppHeader } from "./app/view/components/AppHeader"
import { CircularProgress, Hidden, makeStyles } from "@material-ui/core"
import { auth } from './firebase';
import { AppFooter } from "./app/view/components/AppFooter"

const Home = lazy(() =>
    import(/* webpackChunkName: "home" */ './app/view/Home'),
)
const Settings = lazy(() =>
    import(/* webpackChunkName: "settings" */ './app/view/Settings'),
)
const Game = lazy(() =>
    import(/* webpackChunkName: "game" */ './app/view/game/Game'),
)
const About = lazy(() =>
    import(/* webpackChunkName: "about" */ './app/view/About'),
)
const Words = lazy(() =>
    import(/* webpackChunkName: "words" */ './app/view/Words'),
)
const Manage = lazy(() =>
    import(/* webpackChunkName: "manage" */ './app/view/Manage'),
)
const ActiveRound = lazy(() =>
    import(/* webpackChunkName: "activeround" */ './app/view/game/components/ActiveRound'),
)
const game = new GameController()
game.init();

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  screenContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    justifyItems: 'center',
    marginBottom: 50
  },
  appRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }

}))


function App() {
  const classes = useStyles();

  useEffect(() => {
    auth.onAuthStateChanged((currentUser: any) => game.auth.handleAuthChange(currentUser))
  }, [])

  // @ts-ignore
  if (window.update) {
    console.log('Application was updated. Please reload')
  }

  if (!game.isReady) {
    return (
        <Suspense
            fallback={
              <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
              >
                  <CircularProgress size={20} color="primary"/>
              </div>
            }
        >
          <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
          >
            <CircularProgress size={20} color="primary"/>
          </div>
        </Suspense>
    )
  }
  return (

      <Suspense
          fallback={
            <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
            >
                <CircularProgress size={20} color="primary"/>
            </div>
          }
      >
        <div className={classes.appRoot}>
          <Hidden smDown={true}>
            <div style={{ width: '100%' }}>
              <AppHeader game={game}/>
            </div>
            <div className={classes.offset}/>
          </Hidden>
          <div className={classes.screenContainer}>
            {game.screen === 'Home' && <Home game={game}/>}
            {game.screen === 'Play' && game.roundIntro && <ActiveRound game={game}/>}
            {game.screen === 'Play' && !game.roundIntro && <Game game={game}/>}
            {game.screen === 'About' && <About game={game}/>}
            {game.screen === 'Settings' && <Settings game={game}/>}
            {game.screen === 'Words' && <Words game={game}/>}
            {game.screen === 'Manage' && <Manage game={game}/>}
          </div>
          <div style={{ color: '#b8d22d' }}>{game.auth.currentUser.displayName || ''}</div>
          <Hidden mdUp={true}>
            <AppFooter game={game}/>
          </Hidden>
        </div>
      </Suspense>
  );
}

export default observer(App);
