import { database } from '../../firebase';

export class FirebaseController {

  adminRef = database.ref('/roles/admin')
  usersRef = database.ref('/users')
  wordsRef = database.ref('/words')

  wordsSnapshot: any

}
