import { makeAutoObservable, toJS } from "mobx"
import { GameController } from "./GameController"
import ReactGA from "react-ga"
import {Word} from '../model/Word'

export class WordController {


  public manageWordList: Word[] = []
  private manageWordSnapshot: any = []

  private gameState: GameController

  constructor(gameState: GameController) {
    this.gameState = gameState
    makeAutoObservable(this)
  }

  fetchManageWords() {
    this.gameState.firebase.wordsRef
    .orderByChild('approved')
    .equalTo(false)
    .once('value', (snapshot: any) => {
      const words: Word[] = [];
      snapshot.forEach((childSnapshot: any) => {
        const word: Word = childSnapshot.val()
        this.manageWordSnapshot.push({id: childSnapshot.key, label: word.label })
        words.push(childSnapshot.val())
      });
      this.manageWordList = words
    })
  }

  isValidWord(word: Word) {
    if (this.gameState.fullWordList.findIndex((existingWord: Word) => existingWord.label === word.label) > -1) {
      return false
    }
    return true
  }

  addWord(word: Word) {
    if (this.isValidWord(word)) {
      word.createdBy = this.gameState.auth.currentUser.email
      word.createdByDisplay = this.gameState.auth.currentUser.displayName
      word.approved = this.gameState.auth.isInRole('admin')
      word.createdDate = new Date().toISOString()
      this.gameState.firebase.wordsRef.push(word)
    }

    ReactGA.event({
      category: 'App',
      action: 'add_word'
    });

  }

  updateWord(word: Word) {
      word.createdBy = this.gameState.auth.currentUser.email
      word.createdByDisplay = this.gameState.auth.currentUser.displayName
      word.approved = this.gameState.auth.isInRole('admin')
      word.createdDate = new Date().toISOString()
      const item = toJS(this.manageWordSnapshot.find((w:any) => w.label === word.label ))
      this.gameState.firebase.wordsRef.child(item.id).update(toJS(word))

    ReactGA.event({
      category: 'App',
      action: 'update_word'
    });

  }

}
