import { makeAutoObservable, runInAction } from "mobx"
import { GameController } from "./GameController"
import { User } from "../model/User"

export class AuthController {

  public currentUser: User
  public userRoles: string[] = []

  private gameController: GameController

  constructor(gameController: GameController) {
    this.gameController = gameController
    makeAutoObservable(this)
    this.currentUser = {
      email: '',
      photoURL: '',
      displayName: ''
    }
  }

  handleAuthChange(currentUser: any) {
    this.gameController.eventLog(currentUser ? 'login' : 'logout', { 'action': 'auth_change' })
    runInAction(() => {

      if (currentUser) {
        this.currentUser = {
          email: currentUser.email,
          photoURL: currentUser.photoURL,
          displayName: currentUser.displayName,
          createdDate: new Date().toISOString(),
          lastActiveDate: new Date().toISOString(),
        }
        this.logUser(currentUser)
        this.getUserRoles(currentUser)

      } else {
        this.currentUser = {}
      }
    })
  }

  logUser(currentUser: User) {
    // Add user to users database if not exist
    // Otherwise update lastActive
    this.gameController.firebase.usersRef
    .orderByChild('email')
    .equalTo(currentUser.email!.toString())
    .once('value', (snapshot) => {
      const userData = snapshot.val();
      if (!userData) {
        this.gameController.firebase.usersRef.push(this.currentUser);
      } else {
        this.gameController.firebase.usersRef.update({ lastActiveDate: new Date().toISOString() });
      }
    });
  }

  getUserRoles(currentUser: User) {
    // Add user to users database if not exist
    // Otherwise update lastActive
    this.gameController.firebase.adminRef
    .orderByChild('email')
    .equalTo(currentUser.email!.toString())
    .once('value', (snapshot) => {
      this.userRoles.push('admin')
      this.gameController.eventLog('login_admin', { 'user': currentUser.email!.toString() })

    });
  }

  isInRole(role: string) {
    return this.userRoles.includes(role)
  }
}
