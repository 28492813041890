import { makeAutoObservable, runInAction } from "mobx"
import { audioBeat } from "./AudioController"

export class CountDownTimerController {
  public secondsRemaining = 60
  public secondsDefault = 60
  public interval: any
  public isRunning: boolean = false

  constructor(seconds: number) {
    this.secondsRemaining = seconds
    this.secondsDefault = seconds
    makeAutoObservable(this)
  }

  descreaseTimer() {
    runInAction(() =>{
      this.secondsRemaining = this.secondsRemaining -1
    })
  }

  measure(){
    if(!this.isRunning) return
    this.descreaseTimer()
    setTimeout(() => this.measure(), 1000)
  }

  start(){
    if(this.isRunning) return
    this.isRunning = true
    this.measure()
  }

  pause(){
    if(!this.isRunning) return
    this.isRunning = false
    audioBeat.pause();
  }

  reset(){
    this.secondsRemaining = this.secondsDefault
    audioBeat.currentTime = 0
  }

  setDefault(seconds:number){
    this.secondsDefault = seconds
  }

}
