import React, { useState } from 'react';
import { GameController } from "../../controller/GameController"
import { observer } from "mobx-react"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AddCircle,
  Chat,
  ContactSupport,
  ExitToApp,
  Home as HomeIcon,
  Settings as SettingsIcon
} from "@material-ui/icons"
import { auth, googleAuthProvider } from '../../../firebase';
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core"
import { runInAction } from "mobx"

type AppFooterProps = { game: GameController }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      userButton: {
        cursor: 'pointer',
        height: 22,
        marginTop: 2,
        borderRadius: '50%'
      },
      bottomNav: {
        backgroundColor: 'rgb(153 169 69)',
        boxShadow: '0px -7px 8px -6px rgba(0,0,0,0.63);',
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        '& .Mui-selected': {
          color: '#ffffff'
        }
      }
    }),
);

export function AppFooter(props: AppFooterProps) {
  const classes = useStyles();
  const [navValue, setNavValue] = useState()

  // AuthController Events
  function signIn() {
    props.game.eventLog( 'sign_in_attempt' , { 'action': 'auth_change' })
    auth.signInWithPopup(googleAuthProvider)
  }

  function signOut() {
    props.game.eventLog( 'sign_out' , { 'action': 'auth_change' })
    auth.signOut();
  }

  function displayCurrentUser() {
    return <BottomNavigationAction onClick={signOut} value="" label="Sign out" icon={<img
        className={classes.userButton}
        onClick={signOut}
        src={props.game.auth.currentUser.photoURL}
        alt={props.game.auth.currentUser.displayName}
    />}/>
  }

  return (
      <BottomNavigation
          value={navValue}
          onChange={(event, newValue) => {
            if (newValue === '') {
              return
            }
            setNavValue(newValue)
            props.game.setScreen(newValue)
          }
          }
          showLabels
          className={classes.bottomNav}
      >
        <BottomNavigationAction value="Home" label="Home" icon={<HomeIcon/>}/>
        <BottomNavigationAction value="Play" label="Play" icon={<Chat/>}/>
        {
          props.game.auth.currentUser.email
              ? <BottomNavigationAction value="Words" label="Words" icon={<AddCircle/>}/>
              : <BottomNavigationAction value="About" label="About" icon={<ContactSupport/>}/>

        }
        <BottomNavigationAction value="Settings" label="Settings" icon={<SettingsIcon/>}/>
        {
          props.game.auth.currentUser.email
              ? displayCurrentUser()
              : <BottomNavigationAction onClick={signIn} value="" label="Sign In"
                                        icon={<ExitToApp/>}/>
        }
      </BottomNavigation>
  )
}

export default observer(AppFooter)
