import React from 'react';
import { GameController } from "../../controller/GameController"
import { observer } from "mobx-react"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { AddCircle, ContactSupport, Home, Settings, SupervisorAccount } from "@material-ui/icons"
import { auth, googleAuthProvider } from '../../../firebase';
import { Button } from "@material-ui/core"

type AppHeaderProps = { game: GameController }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      appBar: {
        backgroundColor: 'rgb(153 169 69)',
      },
      iconButton: {
        color: theme.palette.grey["200"]
      },
      grow: {
        flexGrow: 1,
      },
      title: {
        flexGrow: 1,
      },
      userButton: {
        cursor: 'pointer',
        marginLeft: 10,
        height: 28,
        marginTop: 4,
        borderRadius: '50%'
      }
    }),
);

export function AppHeader(props: AppHeaderProps) {
  const classes = useStyles();

  // AuthController Events
  function signIn() {
    props.game.eventLog( 'sign_in_attempt' , { 'action': 'auth_change' })
    auth.signInWithPopup(googleAuthProvider)
  }

  function signOut() {
    props.game.eventLog( 'sign_out' , { 'action': 'auth_change' })
    auth.signOut();
  }

  function displayCurrentUser() {
    return <img
        className={classes.userButton}
        onClick={signOut}
        src={props.game.auth.currentUser.photoURL}
        alt={props.game.auth.currentUser.displayName}
    />
  }

  return (
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <IconButton className={classes.iconButton}
                      onClick={() => props.game.setScreen('Home')}><Home/></IconButton>
          <Button className={classes.iconButton} onClick={() => props.game.setScreen('Play')}>
            Play</Button>
          <div className={classes.grow}/>
          {
            props.game.auth.currentUser.email && (
                <IconButton className={classes.iconButton}
                            onClick={() => props.game.setScreen('Words')}><AddCircle/></IconButton>
            )
          }
          <IconButton className={classes.iconButton}
                      onClick={() => props.game.setScreen('About')}><ContactSupport/></IconButton>
          <IconButton className={classes.iconButton}
                      onClick={() => props.game.setScreen('Settings')}><Settings/></IconButton>
          <span>{props.game.auth.currentUser.email
              ? displayCurrentUser()
              : <Button className={classes.iconButton} onClick={signIn}>Sign In</Button>}
          </span>
          {
            props.game.auth.userRoles.includes('admin') && (
                <IconButton
                    className={classes.iconButton}
                    onClick={() => props.game.setScreen('Manage')}>
                  <SupervisorAccount />
                </IconButton>
            )
          }
        </Toolbar>
      </AppBar>
  )
}

export default observer(AppHeader)
