import { makeAutoObservable } from "mobx"
import {  Team } from "../model/Team"
import { Category } from "../model/Category"

export const initialRounds = [
  {
    round: 0,
    wordCorrect: [],
    wordPass: []
  },
  {
    round: 1,
    wordCorrect: [],
    wordPass: []
  },
  {
    round: 2,
    wordCorrect: [],
    wordPass: []
  }
]

const initialTeams = [
  {
    name: 'Team Smarties',
    standing: initialRounds
  },
  {
    name: 'Team Wise',
    standing: initialRounds
  },
  {
    name: 'Team3',
    standing: initialRounds
  }
]

const soundList = [
  'navigation',
  'timerStart',
  'timerPause',
  'timerExpire',
  'timerReset',
  'beat',
  'cardCorrect',
  'cardPass',
  'roundStart',
  'roundOver',
  'undo',
  'invalid',
  'nextTeam'
]

const defaultActiveSounds =['timerStart','timerPause','beat','timerExpire','roundOver']

export class SettingsController {

  public numTeams: number = 2
  public defaultTeams: Team[] = initialTeams
  public numCards: number = 30
  public countDown: number = 60
  public useSounds: boolean = true
  public everything: boolean = true
  public categories: Category[] = []
  public activeCategories: string[] = []
  public wordDifficulty: number[] = [1, 5]

  public sounds: string[] = soundList
  public activeSounds: string[] = defaultActiveSounds

  constructor() {
    makeAutoObservable(this)
    this.getSettings()
    this.categories = this.loadCategories()
    this.categories.map(category => this.activeCategories.push(category.name))
    this.activeCategories.splice(this.activeCategories.findIndex(item => item === 'Adult'), 1)

  }

  getSettings(){
    let settings: any = window.localStorage.getItem('settings')
    if(settings){
      settings = JSON.parse(settings)
      this.useSounds = settings.useSounds
      this.activeSounds = settings.activeSounds
      this.activeCategories = settings.activeCategories
      this.wordDifficulty = settings.wordDifficulty
      this.everything = settings.everything
      this.numCards = settings.numCards
      this.countDown = settings.countDown
      this.numTeams = settings.numTeams
      this.defaultTeams = settings.defaultTeams
    } else {
      this.setSettings()
    }
  }

  setSettings() {
    window.localStorage.setItem('settings',JSON.stringify(this.buildSettings()))
  }

  buildSettings() {
    return {
      useSounds: this.useSounds,
      activeSounds: this.activeSounds,
      activeCategories: this.activeCategories,
      wordDifficulty: this.wordDifficulty,
      everything: this.everything,
      numCards: this.numCards,
      countDown: this.countDown,
      numTeams: this.numTeams,
      defaultTeams: this.defaultTeams
    }
  }

  loadCategories() {
    const categories = [
      {
        id: '1',
        name: 'Kids'
      },
      {
        id: '2',
        name: 'Entertainment'
      },
      {
        id: '3',
        name: 'Food'
      },
      {
        id: '4',
        name: 'Brands'
      },
      {
        id: '5',
        name: 'Education'
      },
      {
        id: '6',
        name: 'Science'
      },
      {
        id: '7',
        name: 'Seasonal'
      },
      {
        id: '8',
        name: 'Geography'
      },
      {
        id: '9',
        name: 'History'
      },
      {
        id: '10',
        name: 'Sports'
      },
      {
        id: '11',
        name: 'Lifestyle'
      },
      {
        id: '12',
        name: 'Sayings'
      },
      {
        id: '12',
        name: 'Business'
      },
      {
        id: '13',
        name: 'Technology'
      },
      {
        id: '14',
        name: 'Activities'
      },
      {
        id: '15',
        name: 'Professions'
      },
      {
        id: '16',
        name: 'Physical World'
      },
      {
        id: '17',
        name: 'Spirituality'
      },
      {
        id: '18',
        name: 'Living World'
      },
      {
        id: '19',
        name: 'Slang'
      },
      {
        id: '20',
        name: 'People'
      },
      {
        id: '21',
        name: 'Humanity'
      }
    ]
    return categories
  }

  setTeamName(index: number, value: string) {
    this.defaultTeams[index].name = value
  }

}
