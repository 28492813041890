import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// import 'firebase/messaging';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyAzyuIzP_wQ2HIyeSQLD8DSjpZhq8C5AUs",
  // authDomain: "mixed-d9ad5.firebaseapp.com",
  authDomain: "auth.mixed.games",
  databaseURL: "https://mixed-d9ad5-default-rtdb.firebaseio.com",
  projectId: "mixed-d9ad5",
  storageBucket: "mixed-d9ad5.appspot.com",
  messagingSenderId: "677490727674",
  appId: "1:677490727674:web:52af9bdf83c168d99f4743",
  measurementId: "G-H14MMNT05W"
};

firebase.initializeApp(config);

export default firebase;

export const database = firebase.database();
export const store = firebase.firestore();
export const auth = firebase.default.auth();
export const storage = firebase.storage();
export const googleAuthProvider = new firebase.default.auth.GoogleAuthProvider();
// export const messaging = firebase.messaging();
