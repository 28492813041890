import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4b772b',
    },
    secondary: {
      main: '#7ed25a',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#b8d22d',
    },

  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontFamily: 'Roboto, cursive',
      }
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: '#4b772b !important',
        },
      }
    },
  }
});

export default theme;
