import { makeAutoObservable } from "mobx"
import { CountDownTimerController } from "./CountDownTimerController"
import { initialRounds, SettingsController } from "./SettingsController"
import { FirebaseController } from "./FirebaseController"
import { AuthController } from "./AuthController"
import { WordController } from "./WordController"
import { audioBeat, AudioController, audioExpire } from "./AudioController"
import ReactGA from 'react-ga';

import { Round, Team, Word } from "../model/Index"

ReactGA.initialize('G-NJRCCDBJFE');


const defaultRounds = [
  {
    id: 1,
    label: 'One',
    description: 'Describe it'
  },
  {
    id: 2,
    label: 'Two',
    description: 'Charades'
  },
  {
    id: 3,
    label: 'Three',
    description: 'One Word'
  }
]



export class GameController {

  public isReady: boolean = false

  public screen: string = window.localStorage.getItem('screen') || 'Home'
  public teams: Team[]
  public activeTeamIndex: number = 0
  public rounds: Round[] = defaultRounds
  public round: number = 0
  public roundIntro: boolean = true
  public roundSummary: boolean = false
  public gameSummary: boolean = false

  public words: Word[] = []
  public remainingWords: Word[] = []
  public fullWordList: Word[] = []
  public wordsUsedInSession: Word[] = []
  public activeWord?: Word

  public timer: CountDownTimerController
  public settings: SettingsController
  public firebase: FirebaseController
  public auth: AuthController
  public wordState: WordController
  public audioState: AudioController

  constructor() {
    makeAutoObservable(this)
    this.firebase = new FirebaseController()
    this.settings = new SettingsController()
    this.auth = new AuthController(this)
    this.wordState = new WordController(this)
    this.audioState = new AudioController(this)
    this.timer = new CountDownTimerController(this.settings.countDown)
    this.teams = this.settings.defaultTeams.slice(0, this.settings.numTeams)
  }

  async init() {
    this.eventLog('App',{'action':'init'})
    this.loadWords()
  }

  reset() {
    this.teams = this.settings.defaultTeams.slice(0, this.settings.numTeams)
    this.teams.map(team => {
      team.standing = initialRounds
      return team
    })
    this.remainingWords = []
    this.shuffleArray(this.fullWordList)

    if (this.screen === 'Play') {
      this.screen = 'Home'
    }
    this.gameSummary = false
    this.roundSummary = false
    this.timer.pause()
    this.timer.reset()
    this.words = this.pickWords()
    // console.log(toJS(this.words),toJS(this.settings.activeCategories))
    this.setRound(0)
    this.eventLog('reset_game',{'action':'reset'})
  }

  setScreen(screen: string) {
    this.screen = screen

    window.localStorage.setItem('screen', screen)
    // this needs to be isolated to the page

    if (this.screen === 'Manage') {
      this.wordState.fetchManageWords()
    }

    if (this.screen === 'Play') {
      audioExpire.play().then(() => {
        // pause directly
        audioExpire.currentTime = 0;
        audioExpire.pause();
      });
      audioBeat.play().then(() => {
        // pause directly
        audioBeat.currentTime = 0;
        audioBeat.pause();
      });
    }

    this.eventLog('screen_' + screen.toLowerCase(),{'action':'screen', 'label': screen})
  }

  get activeTeam() {
    return this.teams[this.activeTeamIndex]
  }

  getTeamPoints(id: number) {
    const points = this.teams[id].standing.reduce((acc, cur) => {
      return acc + (cur.wordCorrect.length - cur.wordPass.length)
    }, 0)
    return points
  }

  setRound(round: number) {
    this.round = round
    this.activeWord = undefined
    this.shuffleArray(this.words)
    this.remainingWords.push(...this.words)
  }

  shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  loadWords() {
    const lastLoaded: any = window.localStorage.getItem('wordsLastLoaded')

    if (lastLoaded) {
      const difference = new Date().getTime() - new Date(lastLoaded).getTime()
      const days = Math.ceil(difference / (1000 * 3600 * 24));
      if (days <= 1) {
        const words: Word[] = JSON.parse(window.localStorage.getItem('wordFullList') || '[]');
        this.prepWords(words)
        return
      }
    }

    this.fetchWords()
  }

  fetchWords(approved: boolean = true) {
    this.firebase.wordsRef
    .orderByChild('approved')
    .equalTo(approved)
    .once('value', (snapshot: any) => {
      const words: Word[] = [];
      this.firebase.wordsSnapshot = snapshot.val()
      snapshot.forEach((childSnapshot: any) => {
        words.push(childSnapshot.val())
      });
      this.cacheWords(words)
      this.prepWords(words)
      // console.log(words, toJS(this.fullWordList), toJS(this.words));
    });
  }


  cacheWords(words: Word[]) {
    window.localStorage.setItem('wordsLastLoaded', new Date().toISOString())
    window.localStorage.setItem('wordFullList', JSON.stringify(words))
  }

  prepWords(words: Word[]) {
    this.shuffleArray(words)
    this.fullWordList = words
    this.reset()
    this.isReady = true
  }

  pickWords() {
    let potentialList = this.fullWordList
    .filter(word => !this.wordsUsedInSession.includes(word))
    .filter(word => this.matchesCategory(word))
    .slice(0, this.settings.numCards)

    this.wordsUsedInSession.push(...potentialList)

    return potentialList
  }

  matchesCategory(word: Word) {
    if (!this.settings.everything && this.settings.activeCategories.length > 0) {
      return this.settings.activeCategories.includes(word.category.name)
    }
    return true
  }

  nextWord() {
    return this.remainingWords.shift()
  }

  nextTeam(noSound?: boolean) {
    if (!noSound) {
      this.audioState.playNextTeam()
    }
    if (this.activeWord) {
      this.remainingWords.push(this.activeWord)
      this.activeWord = undefined
    }
    this.timer.pause()
    this.timer.reset()
    this.activeTeamIndex = this.activeTeamIndex === this.teams.length - 1
        ? 0
        : this.activeTeamIndex + 1
  }

  setActiveTeam(index: number) {
    this.audioState.playNextTeam()

    if (this.activeWord) {
      this.remainingWords.push(this.activeWord)
      this.activeWord = undefined
    }
    this.timer.pause()
    this.timer.reset()
    this.activeTeamIndex = index
  }

  timerStart() {
    this.audioState.playStartTimer()
    this.timer.start()
    if (!this.activeWord) {
      this.activeWord = this.nextWord()
    }
  }

  timerPause() {
    this.audioState.playPauseTimer()
    this.timer.pause()
  }

  timerReset() {
    this.timer.pause()
    this.timer.reset()
    this.audioState.playResetTimer()
  }

  timerExpire() {
    this.remainingWords.push(this.activeWord!)
    this.activeWord = undefined
    this.timer.pause()
    this.timer.reset()
    this.nextTeam(true)
  }

  correctWord() {
    this.audioState.playCorrect()
    this.teams[this.activeTeamIndex].standing[this.round].wordCorrect.push(this.activeWord!)
    this.activeWord = this.nextWord()
    this.isRoundOver()
  }

  passWord() {
    this.audioState.playPass()
    this.teams[this.activeTeamIndex].standing[this.round].wordPass.push(this.activeWord!)
    this.activeWord = this.nextWord()
    this.isRoundOver()
  }

  isRoundOver() {
    if (!this.activeWord) {
      this.timer.pause()
      this.timer.reset()
      if (this.round >= 2) {
        this.gameSummary = true
        this.roundSummary = false
        this.audioState.playGameOver()
      } else {
        this.roundSummary = true
        this.audioState.playRoundOver()
      }

      this.eventLog('round_over',{'action':'round_over'})
    }
  }

  startRound() {
    this.roundSummary = false
    this.nextTeam(true)
    this.setRound(this.round + 1)
    this.eventLog('round_start',{'action':'round_start'})
  }

  setRoundIntro(isOn: boolean) {
    if(isOn){
      this.audioState.playRoundStart()
    }
    this.roundIntro = isOn
  }

  undoWord(teamIndex: number, correct: boolean, wordIndex: number) {
    const word: Word = correct
        ? this.teams[teamIndex].standing[this.round].wordCorrect.splice(wordIndex, 1)[0]
        : this.teams[teamIndex].standing[this.round].wordPass.splice(wordIndex, 1)[0]
    this.remainingWords.push(word)
    this.audioState.playUndo()
  }


  winningTeam() {
    let leadingTeamIndex: number = 0

    this.teams.map((team: Team, index: number) => {
      if (this.getTeamPoints(index) > this.getTeamPoints(leadingTeamIndex)) {
        leadingTeamIndex = index
      }
      return team
    })

    this.eventLog('game_over',{'action':'game_over'})
    return this.teams[leadingTeamIndex ].name

  }

 eventLog(label: string, event: any){
    // @ts-ignore
    if(window.gtag !== undefined){
      // @ts-ignore
      window.gtag('event', label, event);
      console.log('event', label, event)
    }
  }


}
